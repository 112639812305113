import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useStory } from "../context/StoryContext";
import { regenerateImagePrompts } from "../services/storyService";
import useImageService from "../services/imageService";
import { useNavigate } from "react-router-dom";

export const useBookPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const imageService = useImageService();
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGenerateImagePrompt, setIsGenerateImagePrompt] = useState(false);
  const { setCurrentStoryId, storyState, dispatch } = useStory();
  const [selectedImages, setSelectedImages] = useState({});
  const [userPhotosRequestId, setuserPhotosRequestId] = useState(null);
  const [generateMode, setGenerateMode] = useState("all");
  const [viewMode, setViewMode] = useState("");
//   const [selectedImageIndex, setSelectedImageIndex] = useState(0);
//   const [faceSize, setFaceSize] = useState(null);
//   const [facePositioning, setFacePositioning] = useState(null);
//   const [angleRatio, setAngleRatio] = useState(null);

  const isCoverPage = storyState.currentPage === 0;

  const leftPage =
    storyState.currentPage === 0
      ? { images: storyState.coverImages }
      : storyState.pages?.find(
          (page) => page.pageNumber === storyState.currentPage
        );
  const leftImages = leftPage?.images || [];
  const sortedCurrentImages = [
    ...leftImages?.filter((image) => image.is_default),
    ...leftImages?.filter((image) => !image.is_default),
  ];

  useEffect(() => {
    if (id && id !== storyState.id) {
      setCurrentStoryId(id);
    }
  }, [id, storyState.id, setCurrentStoryId]);

//   useEffect(() => {
//     if (sortedCurrentImages[selectedImageIndex]) {
//       setFaceSize(sortedCurrentImages[selectedImageIndex].faceSize || null);
//       setFacePositioning(
//         sortedCurrentImages[selectedImageIndex].facePositioning || null
//       );
//       setAngleRatio(sortedCurrentImages[selectedImageIndex].angleRatio || null);
//     }
//   }, [selectedImageIndex, sortedCurrentImages]);

  useEffect(() => {
    setViewMode(storyState.settings.imageLayout || "double");
  }, [storyState.settings.imageLayout]);

  const getCurrentPagePrompt = () => {
    const currentPage = storyState.currentPage;
    return storyState.pages[currentPage - 1]?.userPrompt || "";
  };

  const generateImages = async (imagePrompts) => {
    try {
      const userPhotosRequestId =
        storyState.userPhotosRequestId ||
        storyState.settings.userPhotosRequestId;
      const layout = storyState.settings.imageLayout || "single";
      setIsGenerating(true);
      const updatedImagePrompts = imagePrompts.map((prompt) => {
        const faceSize = prompt.faceSize;
        const facePositioning = prompt.facePositioning;
        const angleRatio = prompt.angleRatio;
        const controlnetStrength = prompt.controlnetStrength;
        console.log("FACE SIZE: ", faceSize);
        console.log("FACE POSITIONING: ", facePositioning);
        console.log("ANGLE RATIO: ", angleRatio);
        console.log("CONTROLNET STRENGTH: ", controlnetStrength);
        const numericFaceSize = parseFloat(faceSize);
        const numericFacePositioning = parseFloat(facePositioning);
        const numericAngleRatio = parseFloat(angleRatio);
        const numericControlnetStrength = parseFloat(controlnetStrength);
        console.log("numericFaceSize: ", numericFaceSize);
        console.log("numericFacePositioning: ", numericFacePositioning);
        console.log("numericAngleRatio: ", numericAngleRatio);
        console.log("numericControlnetStrength: ", numericControlnetStrength);
        return {
          ...prompt,
          faceSize:
            isNaN(numericFaceSize) ||
            numericFaceSize < 0 ||
            numericFaceSize > 100
              ? null
              : numericFaceSize,
          facePositioning:
            isNaN(numericFacePositioning) ||
            numericFacePositioning < 0 ||
            numericFacePositioning > 100
              ? null
              : numericFacePositioning,
          angleRatio:
            isNaN(numericAngleRatio) ||
            numericAngleRatio > 100
            ? null
            : numericAngleRatio,
          controlnetStrength:
            isNaN(numericControlnetStrength) ||
            numericControlnetStrength > 100
            ? null
            : numericControlnetStrength,
        };
      });

      console.log("UPDATED IMAGE PROMPTS: ", updatedImagePrompts);
      if (layout === "double") {
        await imageService.batchGenerateImages(
          updatedImagePrompts,
          userPhotosRequestId,
          layout,
          updateImageCallbackForDoubleLayout,
          setIsGenerating,
          storyState.bookId
        );
      } else {
        await imageService.batchGenerateImages(
          updatedImagePrompts,
          userPhotosRequestId,
          layout,
          updateImageCallback,
          setIsGenerating,
          storyState.bookId
        );
      }
      dispatch({ type: "CLEAR_ALL_SELECTED_IMAGES" });
      setSelectedImages({});
    } 
    catch (error) {
      console.error("Error generating images:", error);
      setIsGenerating(false);
    }
  };
  
  const updateImageCallbackForDoubleLayout = (
    urls,
    pageNumber,
    imagePrompts,
    ids,
    actual_image_urls
  ) => {
    urls.forEach((url, index) => {
      const newImage = {
        imageId: ids[index],
        imagePrompt: imagePrompts[index],
        is_default: true,
        layout_info: {
          position: { x: "10%", y: "20%", width: "80%", height: "60%" },
          zIndex: 1,
          fullPageOverlay: false,
        },
        imageUrl: url,
        actual_image_url: actual_image_urls[index],
        pageNumber: pageNumber + index,
      };

      const payload = { pageNumber: pageNumber + index, image: newImage };
      dispatch({
        type: "ADD_OR_REPLACE_IMAGE",
        payload: payload,
      });

      let curImageArray = storyState.pages[pageNumber + index - 1];
      if (curImageArray && curImageArray.length > 0) {
        let currImage = curImageArray.images[0];
        const storedPrompt = currImage.storedPrompt;
        dispatch({
          type: "UPDATE_IMAGE_PROMPT",
          payload: {
            pageNumber: pageNumber + index,
            imageIndex: 1,
            newPrompt: storedPrompt,
            oldPrompt: "",
          },
        });
      }
    });

    setIsGenerating(false);
  };

  const updateImageCallback = (
    url,
    pageNumber,
    imagePrompt,
    id,
    actual_image_url
  ) => {
    const isCover = pageNumber === 0;
    let curImageArray;
    if (isCover) {
      curImageArray = storyState.coverImages;
    } else {
      curImageArray = storyState.pages[pageNumber - 1]?.images || [];
    }
    const newImage = {
      imageId: id,
      imagePrompt: imagePrompt,
      is_default: true,
      layout_info: {
        position: { x: "10%", y: "20%", width: "80%", height: "60%" },
        zIndex: 1,
        fullPageOverlay: false,
      },
      imageUrl: url,
      actual_image_url: actual_image_url,
      pageNumber: pageNumber,
    };
    const payload = { pageNumber: pageNumber, image: newImage };
    dispatch({
      type: "ADD_OR_REPLACE_IMAGE",
      payload: payload,
    });
    if (curImageArray.length > 0) {
      let currImage = curImageArray[0];
      const storedPrompt = currImage.storedPrompt;
      dispatch({
        type: "UPDATE_IMAGE_PROMPT",
        payload: {
          pageNumber,
          imageIndex: 1,
          newPrompt: storedPrompt,
          oldPrompt: "",
        },
      });
    }

    setIsGenerating(false);
  };

//   const handleGenerateImages = () => {
//     let imagePromptsToGenerate = [];
//     setIsGenerating(true);

//     if (generateMode === "selected") {
//       console.log("SELECTED IMAGES: ", selectedImages);
//       imagePromptsToGenerate = Object.values(selectedImages).map(
//         ({ pageNumber, imageIndex }) => {
//           const page = storyState.pages.find(
//             (page) => page.pageNumber === pageNumber
//           );
//           console.log("PAGE: ", page);

//           return {
//             imagePrompt: page.userPrompt || page.images[0].imagePrompt,
//             imageId: page.images[0].imageUrl
//               ? undefined
//               : page.images[0].imageId,
//             pageNumber: pageNumber,
//             bookId: storyState.bookId,
//           };
//         }
//       );

//       console.log(
//         "SELECTED IMAGE PROMPTS TO GENERATE: ",
//         imagePromptsToGenerate
//       );
//       if (imagePromptsToGenerate.length === 0) {
//         alert("Please select images to regenerate");
//         return;
//       }

//       dispatch({ type: "CLEAR_ALL_SELECTED_IMAGES" });
//       setSelectedImages({});
//     } else {
//       console.log("HOW MANY PAGES: ", storyState.pages);
//       imagePromptsToGenerate = storyState.pages.map((page) => ({
//         imagePrompt: page.images[0].imagePrompt,
//         imageId: page.images[0].imageId,
//         pageNumber: page.pageNumber,
//         bookId: storyState.bookId,
//       }));
//     }

//     console.log("IMAGE PROMPTS TO GENERATE: ", imagePromptsToGenerate);
//     generateImages(imagePromptsToGenerate);
//   };

const setDefaultImage = (selectedImageIndex) => {
    const rightPage =
    storyState.currentPage === 0
      ? { images: storyState.coverImages }
      : storyState.pages?.find(
          (page) => page.pageNumber === (storyState.currentPage+1)
        );
  const rightImages = rightPage?.images || [];
  const sortedRightImages = [
    ...rightImages?.filter((image) => image.is_default),
    ...rightImages?.filter((image) => !image.is_default),
  ];

    const isDoubleLayout = storyState.settings.imageLayout === "double";

    const pageNumber = storyState.currentPage;
    const imageId = sortedCurrentImages[selectedImageIndex].imageId;
    let referenceId = pageNumber === 0 ? storyState.bookId : leftPage.pageId;
    console.log("Selected Image Index: ", selectedImageIndex);
    console.log("Sorted Current Images: ", sortedCurrentImages);
    console.log("Sorted Selected Image: ", sortedCurrentImages[selectedImageIndex]);
    console.log("Sorted Right Images: ", sortedRightImages);
    console.log("Sorted Selected Right Image: ", sortedRightImages[selectedImageIndex]);
    console.log("Is Double Layout: ", isDoubleLayout);
    console.log("Page Number: ", pageNumber);
    console.log("Image ID: ", imageId);
    console.log("Reference ID: ", referenceId);
    const defaultImageData = {
        pageNumber: pageNumber,
        imageId: imageId,
        referenceId: referenceId,
        layout: isDoubleLayout? "double" : "single",
        rightImageId: isDoubleLayout? sortedRightImages[selectedImageIndex].imageId : null,
        rightPageId: isDoubleLayout? rightPage.pageId : null

    }
    const response = imageService.setDefaultImage(defaultImageData);
    if (response) {
        console.log("IMAGE ID We're using: ", imageId);
        console.log("Right image id", isDoubleLayout? sortedRightImages[selectedImageIndex].imageId : null)
        dispatch({
            type: "SET_DEFAULT_IMAGE",
            payload: {
              pageNumber,
              imageId,
              rightImageId: isDoubleLayout ? sortedRightImages[selectedImageIndex].imageId : null,
              layout: isDoubleLayout ? "double" : "single",
            },
          });
    }
  };

  
  const handleImageToggle = (pageNumber, imageId, isCover = false) => {
    const isDoubleLayout = storyState.settings.imageLayout === "double";

    setSelectedImages((prev) => {
      const newSelectedImages = { ...prev };

      if (isCover) {
        const key = "cover";
        if (newSelectedImages[key]) {
          delete newSelectedImages[key];
        } else {
          newSelectedImages[key] = { pageNumber, imageId, isCover };
        }
      } else if (isDoubleLayout && !isCover) {
        const leftKey = `${pageNumber}`;
        const rightKey = `${pageNumber + 1}`;

        if (newSelectedImages[leftKey]) {
          delete newSelectedImages[leftKey];
          delete newSelectedImages[rightKey];
        } else {
          const rightPage = storyState.pages.find(
            (page) => page.pageNumber === pageNumber + 1
          );
          newSelectedImages[leftKey] = { pageNumber, imageId, isCover: false };
          if (rightPage) {
            newSelectedImages[rightKey] = {
              pageNumber: pageNumber + 1,
              imageId: rightPage.images[0].imageId,
              isCover: false,
            };
          }
        }
      } else {
        const key = `${pageNumber}`;
        if (newSelectedImages[key]) {
          delete newSelectedImages[key];
        } else {
          newSelectedImages[key] = { pageNumber, imageId, isCover };
        }
      }

      if (Object.keys(newSelectedImages).length > 0) {
        setGenerateMode("selected");
      }

      return newSelectedImages;
    });

    dispatch({
      type: "TOGGLE_IMAGE_SELECTION",
      payload: { pageNumber, imageId, isCover, isDoubleLayout },
    });
  };

//   const handleRegenerateCurrentPageImage = () => {
//     const isDoubleLayout = storyState.settings.imageLayout === "double";
//     const currentPageNumber = storyState.currentPage;

//     let imagePromptsToGenerate = [];

//     const currentImage = sortedCurrentImages[selectedImageIndex];

//     if (isDoubleLayout) {
//       const leftPagePrompt = {
//         imagePrompt: currentImage.imagePrompt,
//         pageNumber: currentPageNumber,
//         bookId: storyState.bookId,
//       };

//       const rightPagePrompt = {
//         imagePrompt: currentImage.imagePrompt,
//         pageNumber: currentPageNumber + 1,
//         bookId: storyState.bookId,
//       };

//       imagePromptsToGenerate = [leftPagePrompt, rightPagePrompt];
//     } else {
//       const singlePagePrompt = {
//         imagePrompt: currentImage.imagePrompt,
//         pageNumber: currentPageNumber,
//         bookId: storyState.bookId,
//       };

//       imagePromptsToGenerate = [singlePagePrompt];
//     }
//     console.log(imagePromptsToGenerate);
//     generateImages(imagePromptsToGenerate);
//   };

  const toggleGenerateMode = (mode) => {
    setGenerateMode(mode);
    if (mode === "all") {
      setSelectedImages({});
      dispatch({ type: "CLEAR_ALL_SELECTED_IMAGES" });
    }
  };

  const handlePageChange = (newPage) => {
    dispatch({
      type: "UPDATE_PAGE_NUMBER",
      payload: { pageNumber: newPage },
    });
  };

  const imageNavigation = (direction, selectedImageIndex, side = "left") => {
    const currentPage = storyState.currentPage;
    const pageImages =
      side === "right" && viewMode === "double"
        ? storyState.pages[currentPage]?.images || []
        : currentPage === 0
        ? storyState.coverImages
        : storyState.pages[currentPage - 1]?.images || [];

    let newIndex;
    switch (direction) {
      case "next":
        newIndex = (selectedImageIndex + 1) % pageImages.length;
        break;
      case "prev":
        newIndex =
          (selectedImageIndex - 1 + pageImages.length) % pageImages.length;
        break;
      case "first":
        newIndex = 0;
        break;
      default:
        newIndex = selectedImageIndex;
    }
    console.log("GO BACK TO FIRST IMAGE: ", newIndex);
    return newIndex
  };


  const regeneratePrompt = async (selectedImageIndex) => {
    setIsGenerateImagePrompt(true);
    try {
      const pageNumber = storyState.currentPage;
      const response = await regenerateImagePrompts(storyState, [pageNumber]);
      const updatedPrompt = response[0];
      const newPrompt = updatedPrompt.prompt;
      handlePromptChange(newPrompt);

      dispatch({
        type: "UPDATE_IMAGE_PROMPT",
        payload: {
          pageNumber,
          imageIndex: selectedImageIndex,
          newPrompt,
          oldPrompt: sortedCurrentImages[selectedImageIndex].imagePrompt,
        },
      });
    } catch (error) {
      console.error("Failed to regenerate prompt:", error);
    } finally {
      setIsGenerateImagePrompt(false);
    }
  };

  const handlePromptChange = (newPrompt) => {
    dispatch({
      type: "UPDATE_USER_PROMPT",
      payload: { pageNumber: storyState.currentPage, userPrompt: newPrompt },
    });
  };

  const handleFaceSizeChange = (faceSize) => {
    dispatch({
      type: "UPDATE_FACE_SIZE",
      payload: { pageNumber: storyState.currentPage, faceSize },
    });
  };

  const handleFacePositioninChange = (facePositioning) => {
    dispatch({
      type: "UPDATE_FACE_POSITION",
      payload: { pageNumber: storyState.currentPage, facePositioning },
    });
  }

  const handleAngleRatioChange = (angleRatio) => {
    dispatch({
      type: "UPDATE_ANGLE_RATIO",
      payload: { pageNumber: storyState.currentPage, angleRatio },
    });
  }

  const handleControlnetStrengthChange = (controlnetStrength) => {
    console.log("BRUH: ", controlnetStrength);
    dispatch({
      type: "UPDATE_CONTROLNET_STRENGTH",
      payload: { pageNumber: storyState.currentPage, controlnetStrength
      },
    });
  }

  const handleNewSeedToggled = () => {
    dispatch({
      type: "TOGGLE_NEW_SEED",
      payload: { pageNumber: storyState.currentPage },
    });
  }
  
  
  return {
    isGenerating,
    isGenerateImagePrompt,
    storyState,
    selectedImages,
    userPhotosRequestId,
    generateMode,
    viewMode,
    // selectedImageIndex,
    // faceSize,
    // facePositioning,
    // angleRatio,
    isCoverPage,
    sortedCurrentImages,
    getCurrentPagePrompt,
    generateImages,
    // handleGenerateImages,
    handleImageToggle,
    // handleRegenerateCurrentPageImage,
    handleNewSeedToggled,
    toggleGenerateMode,
    handlePageChange,
    imageNavigation,
    regeneratePrompt,
    setDefaultImage,
    handlePromptChange,
    handleFaceSizeChange,
    handleFacePositioninChange,
    handleAngleRatioChange,
    handleControlnetStrengthChange,
    setuserPhotosRequestId,
    navigate,
  };
};