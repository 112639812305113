import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStory } from "../context/StoryContext";
import useImageService from "../services/imageService";

const useStoryEditor = () => {
  const navigate = useNavigate();
  const { storyState, setCurrentStoryId, dispatch, saveTitle } = useStory();
  const imageService = useImageService();

  const { id } = useParams();

  const [title, setTitle] = useState("");
  const [coverImagePrompt, setCoverImagePrompt] = useState("");
  const [coverOptions, setCoverOptions] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);
  
  const [frontCover, setFrontCover] = useState(null);
  const [backCover, setBackCover] = useState(null);

  const [selectedCover, setSelectedCover] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(null);

  const [isGeneratingCover, setIsGeneratingCover] = useState(false);

  const [coverImageResponse, setCoverImageResponse] = useState(null);

  const [defaultCover, setDefaultCover] = useState(null);
  const [faceSize, setFaceSize] = useState(null);
  const [facePositioning, setFacePositioning] = useState(null);
  const [angleRatio, setAngleRatio] = useState(null);
  const [controlnetStrength, setControlnetStrength] = useState(null);

  const bookId = storyState.bookId;
  useEffect(() => {
    if (id && id !== storyState.id) {
      setCurrentStoryId(id);
    }
  }, [id, storyState.id, setCurrentStoryId]);

  useEffect(() => {
    if (storyState) {
      setTitle(storyState.title || "");
      setCoverImagePrompt(storyState.coverImages[0]?.imagePrompt || "");
      setCoverOptions(
        storyState.coverImages.filter((image) => image.imageUrl) || []
      );
      setTitleOptions(storyState.titleImages || []);
      setSelectedCover(storyState.selectedCover || null);
      setSelectedTitle(storyState.selectedTitle || null);
      setFaceSize(frontCover?.faceSize || null);
      setFacePositioning(frontCover?.facePositioning || null);
      setAngleRatio(frontCover?.angleRatio || null);
      setControlnetStrength(frontCover?.controlnetStrength || null);
      console.log("STORY STATE COVER IMAGES: ", storyState.coverImages);
      const frontCoverImage =
        storyState.coverImages.find(
          (img) => img.is_front_cover && img.is_default
        ) || [];
      const backCoverImage =
        storyState.coverImages.find(
          (img) => !img.is_front_cover && img.is_default
        ) || [];
      setFrontCover(frontCoverImage || null);
      setBackCover(backCoverImage || null);
      const defaultCoverImage = storyState.coverImages.find(
        (image) => image.is_default
      );
      setDefaultCover(defaultCoverImage || null);

      const newCoverOptions = storyState.coverImages.filter(
        (image) => image.imageUrl
      );
      if (newCoverOptions.length > 0) {
        setCoverOptions((prevOptions) => {
          const uniqueOptions = [...prevOptions];
          newCoverOptions.forEach((newOption) => {
            if (
              !uniqueOptions.some(
                (option) => option.imageId === newOption.imageId
              )
            ) {
              uniqueOptions.push(newOption);
            }
          });
          return uniqueOptions;
        });
      }
    }
  }, [storyState]);

  const handleSetCover = async (cover, isFrontCover) => {
    try {
      const response = await imageService.setCover(
        cover.imageId,
        storyState.bookId,
        isFrontCover
      );
      if (response) {
        dispatch({
          type: "SET_COVER",
          payload: { cover, isFrontCover },
        });
        if (isFrontCover) {
          setFrontCover(cover);
        } else {
          setBackCover(cover);
        }
      }
    } catch (error) {
      console.error(
        `Failed to set ${isFrontCover ? "front" : "back"} cover:`,
        error
      );
    }
  };

  useEffect(() => {
    if (coverImageResponse) {
      console.log("COVER IMAGE RESPONSE: ", coverImageResponse);
      imageService.pollForCoverImageResults(
        coverImageResponse.status_id,
        bookId,
        updateCoverImageCallback,
        coverImageResponse.image_id
      );
    }
  }, [coverImageResponse]);

  const handleTitleSave = async () => {
    await saveTitle();
  };

  const handleGenerateCoverOptions = async () => {
    if (!coverImagePrompt.trim()) {
      alert("Please enter a cover image prompt before generating.");
      return;
    }
    if (!storyState.userPhotosRequestId) {
      alert("Please select or upload a photo before generating cover options.");
      return;
    }

    setIsGeneratingCover(true);
    try {
      const existingCoverImage = storyState.coverImages[0];
      const imageId =
        existingCoverImage && !existingCoverImage.imageUrl
          ? existingCoverImage.imageId
          : null;
      const userPhotosRequestId =
        storyState.userPhotosRequestId ||
        storyState.settings.userPhotosRequestId;
      const response = await imageService.generateCoverImages(
        storyState.bookId,
        title,
        coverImagePrompt,
        userPhotosRequestId,
        imageId,
        {
          faceSize: parseFloat(faceSize),
          facePositioning: parseFloat(facePositioning),
          angleRatio: parseFloat(angleRatio),
          controlnetStrength: parseFloat(controlnetStrength),
        }
      );
      console.log("COVER RESPONSE: ", response);
      setCoverImageResponse(response);
    } catch (error) {
      console.error("Failed to initiate cover image generation:", error);
      alert("Failed to generate cover options. Please try again.");
      setIsGeneratingCover(false);
    }
  };

  const updateCoverImageCallback = (images) => {
    setCoverOptions((prevCoverOptions) => [...prevCoverOptions, ...images]);
    const recentImage = images[0];
    setIsGeneratingCover(false);
    dispatch({
      type: "ADD_OR_REPLACE_IMAGE",
      payload: {
        pageNumber: 0,
        image: {
          imageId: recentImage.imageId,
          imagePrompt: recentImage.imagePrompt,
          is_default: true,
          layout_info: {
            position: { x: "10%", y: "20%", width: "80%", height: "60%" },
            zIndex: 1,
            fullPageOverlay: false,
          },
          imageUrl: recentImage.imageUrl,
          pageNumber: 0,
        },
      },
    });
  };

  const handleSelectCover = (cover) => {
    setSelectedCover(cover);
    setCoverImagePrompt(cover.imagePrompt);
  };

  const handleContinueToBook = () => {
    dispatch({ type: "SET_TITLE", payload: title });
    dispatch({ type: "SET_COVER_IMAGE_PROMPT", payload: coverImagePrompt });
    dispatch({ type: "SET_COVER_IMAGE", payload: selectedCover });
    dispatch({ type: "SET_SELECTED_TITLE", payload: selectedTitle });

    navigate(`/book/${storyState.bookId}`);
  };

  const handleTitleChange = (newTitle) => {
    setTitle(newTitle);
    dispatch({ type: "SET_TITLE", payload: newTitle });
  };

  const handleCoverImagePromptChange = (newPrompt) => {
    setCoverImagePrompt(newPrompt);
    dispatch({ type: "SET_COVER_IMAGE_PROMPT", payload: newPrompt });
  };

  return {
    title,
    coverImagePrompt,
    coverOptions,
    titleOptions,
    selectedCover,
    selectedTitle,
    isGeneratingCover,
    defaultCover,
    faceSize,
    facePositioning,
    angleRatio,
    controlnetStrength,
    setControlnetStrength,
    setFaceSize,
    setFacePositioning,
    setAngleRatio,
    handleSetCover,
    handleGenerateCoverOptions,
    handleContinueToBook,
    handleTitleChange,
    handleCoverImagePromptChange,
    handleSelectCover,
    setSelectedTitle,
    handleTitleSave,
    bookId: storyState.id,
    frontCover,
    backCover,
  };
};

export default useStoryEditor;

// return {
//   title,
//   coverImagePrompt,
//   coverOptions,
//   titleOptions,
//   selectedCover,
//   selectedTitle,
//   mergedImage,
//   isGeneratingCover,
//   isGeneratingTitle,
//   defaultCover,
//   handleSetCover,
//   handleGenerateCoverOptions,
//   handleGenerateTitleOptions,
//   handleInsertTitleIntoCover,
//   handleContinueToBook,
//   handleTitleChange,
//   handleCoverImagePromptChange,
//   handleSelectCover,
//   setSelectedTitle,
//   handleTitleSave,
//   backgroundGenerationStatus,
//   bookId: storyState.id,
//   coverImages
// };
// useEffect(() => {
//   if (titleImageStatusId) {
//     imageService.pollForTitleImageResults(titleImageStatusId, bookId, updateTitleImageCallback);
//   }
// }, [titleImageStatusId]);

// useEffect(() => {
//   if (mergeStatusId) {
//     imageService.pollForMergedImageResults(mergeStatusId, bookId, updateMergedImageCallback);
//   }
// }, [mergeStatusId]);

// const handleGenerateTitleOptions = async () => {
//   if (!title.trim()) {
//     alert("Please enter a title before generating.");
//     return;
//   }

//   setIsGeneratingTitle(true);
//   try {
//     const statusId = await imageService.generateTitleImages(storyState.bookId, title, user.id);
//     setTitleImageStatusId(statusId);
//     setBackgroundGenerationStatus({ status: 'pending' });
//   } catch (error) {
//     console.error("Failed to initiate title image generation:", error);
//     alert("Failed to generate title options. Please try again.");
//     setIsGeneratingTitle(false);
//     setBackgroundGenerationStatus({ status: 'error', error: error.message });
//   }
// };

// const updateTitleImageCallback = (images) => {
//   setTitleOptions(prevTitleOptions => [...prevTitleOptions, ...images]);
//   setIsGeneratingTitle(false);
// };

// const updateMergedImageCallback = (mergedImage) => {
//   setMergedImage(mergedImage);
//   setDefaultCover(mergedImage);
//   setIsGeneratingCover(false);
//   setIsGeneratingTitle(false);
//   dispatch({
//     type: "ADD_OR_REPLACE_IMAGE",
//     payload: {
//       pageNumber: 0,
//       image: {
//         imageId: mergedImage.imageId,
//         imagePrompt: mergedImage.imagePrompt,
//         is_default: true,
//         layout_info: {
//           position: { x: "10%", y: "20%", width: "80%", height: "60%" },
//           zIndex: 1,
//           fullPageOverlay: false,
//         },
//         imageUrl: mergedImage.imageUrl,
//         pageNumber: 0,
//       },
//     },
//   });
// };

// const handleInsertTitleIntoCover = async () => {
//   if (!selectedCover || !selectedTitle) {
//     alert("Please select both a cover image and a title image before inserting.");
//     return;
//   }

//   setIsGeneratingCover(true);
//   setIsGeneratingTitle(true);
//   try {
//     const statusId = await imageService.insertTitleIntoCover(storyState.bookId, selectedCover.imageId, selectedTitle.imageId, user.id);
//     setMergeStatusId(statusId);
//   } catch (error) {
//     console.error("Failed to insert title into cover:", error);
//     alert("Failed to insert title into cover. Please try again.");
//     setIsGeneratingCover(false);
//     setIsGeneratingTitle(false);
//   }
// };

// import { useState, useEffect } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import { useStory } from '../context/StoryContext';
// import { useAuth } from '../context/AuthContext';
// import useImageService from '../services/imageService';

// const useStoryEditor = () => {
//   const navigate = useNavigate();
//   const { storyState, setCurrentStoryId, dispatch, saveTitle, setIsSaved } = useStory();
//   const imageService = useImageService();
//   const { user } = useAuth();
//   const { id } = useParams();

//   const [title, setTitle] = useState("");
//   const [coverImagePrompt, setCoverImagePrompt] = useState("");
//   const [coverOptions, setCoverOptions] = useState([]);
//   const [titleOptions, setTitleOptions] = useState([]);
//   const [coverImages, setCoverImages] = useState([]);
//   const [selectedCover, setSelectedCover] = useState(null);
//   const [selectedTitle, setSelectedTitle] = useState(null);
//   const [mergedImage, setMergedImage] = useState(null);
//   const [isGeneratingCover, setIsGeneratingCover] = useState(false);
//   const [isGeneratingTitle, setIsGeneratingTitle] = useState(false);
//   const [coverImageStatusId, setCoverImageStatusId] = useState(null);
//   const [titleImageStatusId, setTitleImageStatusId] = useState(null);
//   const [mergeStatusId, setMergeStatusId] = useState(null);
//   const [defaultCover, setDefaultCover] = useState(null);
//   const [backgroundGenerationStatus, setBackgroundGenerationStatus] = useState(null);
//   console.log(storyState)
//   const bookId = storyState.bookId;
//   // console.log()
//   useEffect(() => {
//     if (id && id !== storyState.id) {
//       setCurrentStoryId(id);
//     }
//   }, [id, storyState.id, setCurrentStoryId]);

//   useEffect(() => {
//     if (storyState) {
//       setTitle(storyState.title || "");
//       setCoverImagePrompt(storyState.coverImages[0]?.imagePrompt || "");
//       setCoverOptions(storyState.coverImages.filter(image => image.imageUrl) || []);
//       setTitleOptions(storyState.titleImages || []);
//       setSelectedCover(storyState.selectedCover || null);
//       setSelectedTitle(storyState.selectedTitle || null);
//       const defaultCoverImage = storyState.coverImages.find(image => image.is_default);
//       setDefaultCover(defaultCoverImage || null);

//       const newCoverOptions = storyState.coverImages.filter(image => image.imageUrl);
//       if (newCoverOptions.length > 0) {
//         setCoverOptions(prevOptions => {
//           const uniqueOptions = [...prevOptions];
//           newCoverOptions.forEach(newOption => {
//             if (!uniqueOptions.some(option => option.imageId === newOption.imageId)) {
//               uniqueOptions.push(newOption);
//             }
//           });
//           return uniqueOptions;
//         });
//       }
//     }
//   }, [storyState]);

//   const handleSetCover = async (cover, isFrontCover) => {
//     try {
//       const response = await imageService.setCover(cover.imageId, storyState.bookId, isFrontCover);
//       if (response) {
//         dispatch({
//           type: "SET_COVER",
//           payload: { cover, isFrontCover },
//         });
//         if (isFrontCover) {
//           setDefaultCover(cover);
//         }
//       }
//     } catch (error) {
//       console.error(`Failed to set ${isFrontCover ? 'front' : 'back'} cover:`, error);
//       // Handle error (e.g., show an error message to the user)
//     }
//   };

//   useEffect(() => {
//     if (coverImageStatusId) {
//       imageService.pollForCoverImageResults(coverImageStatusId, bookId, updateCoverImageCallback);
//     }
//   }, [coverImageStatusId]);

//   useEffect(() => {
//     if (titleImageStatusId) {
//       imageService.pollForTitleImageResults(titleImageStatusId, bookId, updateTitleImageCallback);
//     }
//   }, [titleImageStatusId]);

//   useEffect(() => {
//     if (mergeStatusId) {
//       imageService.pollForMergedImageResults(mergeStatusId, bookId, updateMergedImageCallback);
//     }
//   }, [mergeStatusId]);

//   const handleTitleSave = async () => {
//     await saveTitle();
//   };

//   const handleGenerateCoverOptions = async () => {
//     if (!coverImagePrompt.trim()) {
//       alert("Please enter a cover image prompt before generating.");
//       return;
//     }
//     if(!storyState.userPhotosRequestId) {
//       alert("Please select or upload a photo before generating cover options.");
//       return;
//     }

//     setIsGeneratingCover(true);
//     try {
//       const existingCoverImage = storyState.coverImages[0];
//       const imageId = existingCoverImage && !existingCoverImage.imageUrl ? existingCoverImage.imageId : null;
//       const userPhotosRequestId =
//       storyState.userPhotosRequestId ||
//       storyState.settings.userPhotosRequestId;
//       console.log("STUFF FOR GENERATION: ", storyState.bookId, title, coverImagePrompt, user.id, userPhotosRequestId, imageId)
//       const statusId = await imageService.generateCoverImages(storyState.bookId, title, coverImagePrompt, userPhotosRequestId, imageId);
//       setCoverImageStatusId(statusId);
//       // setBackgroundGenerationStatus({ status: 'pending' });
//     } catch (error) {
//       console.error("Failed to initiate cover image generation:", error);
//       alert("Failed to generate cover options. Please try again.");
//       setIsGeneratingCover(false);
//       // setBackgroundGenerationStatus({ status: 'error', error: error.message });

//     }
//   };

//   const handleGenerateTitleOptions = async () => {
//     if (!title.trim()) {
//       alert("Please enter a title before generating.");
//       return;
//     }

//     setIsGeneratingTitle(true);
//     try {
//       const statusId = await imageService.generateTitleImages(storyState.bookId, title, user.id);
//       setTitleImageStatusId(statusId);
//       setBackgroundGenerationStatus({ status: 'pending' });
//     } catch (error) {
//       console.error("Failed to initiate title image generation:", error);
//       alert("Failed to generate title options. Please try again.");
//       setIsGeneratingTitle(false);
//       setBackgroundGenerationStatus({ status: 'error', error: error.message });
//     }
//   };

//   const updateCoverImageCallback = (images) => {
//     setCoverOptions(prevCoverOptions => [...prevCoverOptions, ...images]);
//     const recentImage = images[0]
//     setIsGeneratingCover(false);
//     dispatch({
//       type: "ADD_OR_REPLACE_IMAGE",
//       payload: {
//         pageNumber: 0,
//         image: {
//           imageId: recentImage.imageId,
//           imagePrompt: recentImage.imagePrompt,
//           is_default: true,
//           layout_info: {
//             position: { x: "10%", y: "20%", width: "80%", height: "60%" },
//             zIndex: 1,
//             fullPageOverlay: false,
//           },
//           imageUrl: recentImage.imageUrl,
//           pageNumber: 0,
//         },
//       },
//     });
//   };

//   const updateTitleImageCallback = (images) => {
//     setTitleOptions(prevTitleOptions => [...prevTitleOptions, ...images]);
//     setIsGeneratingTitle(false);
//   };

//   const updateMergedImageCallback = (mergedImage) => {
//     setMergedImage(mergedImage);
//     setDefaultCover(mergedImage);
//     setIsGeneratingCover(false);
//     setIsGeneratingTitle(false);
//     dispatch({
//       type: "ADD_OR_REPLACE_IMAGE",
//       payload: {
//         pageNumber: 0,
//         image: {
//           imageId: mergedImage.imageId,
//           imagePrompt: mergedImage.imagePrompt,
//           is_default: true,
//           layout_info: {
//             position: { x: "10%", y: "20%", width: "80%", height: "60%" },
//             zIndex: 1,
//             fullPageOverlay: false,
//           },
//           imageUrl: mergedImage.imageUrl,
//           pageNumber: 0,
//         },
//       },
//     });
//   };

//   const handleSelectCover = (cover) => {
//     setSelectedCover(cover);
//     setCoverImagePrompt(cover.imagePrompt);
//   };

//   const handleInsertTitleIntoCover = async () => {
//     if (!selectedCover || !selectedTitle) {
//       alert("Please select both a cover image and a title image before inserting.");
//       return;
//     }

//     setIsGeneratingCover(true);
//     setIsGeneratingTitle(true);
//     try {
//       const statusId = await imageService.insertTitleIntoCover(storyState.bookId, selectedCover.imageId, selectedTitle.imageId, user.id);
//       setMergeStatusId(statusId);
//     } catch (error) {
//       console.error("Failed to insert title into cover:", error);
//       alert("Failed to insert title into cover. Please try again.");
//       setIsGeneratingCover(false);
//       setIsGeneratingTitle(false);
//     }
//   };

//   const handleContinueToBook = () => {
//     dispatch({ type: "SET_TITLE", payload: title });
//     dispatch({ type: "SET_COVER_IMAGE_PROMPT", payload: coverImagePrompt });
//     dispatch({ type: "SET_COVER_IMAGE", payload: selectedCover });
//     dispatch({ type: "SET_SELECTED_TITLE", payload: selectedTitle });

//     navigate(`/book/${storyState.bookId}`);
//   };

//   const handleTitleChange = (newTitle) => {
//     setTitle(newTitle);
//     dispatch({ type: "SET_TITLE", payload: newTitle });
//   };

//   const handleCoverImagePromptChange = (newPrompt) => {
//     setCoverImagePrompt(newPrompt);
//     dispatch({ type: "SET_COVER_IMAGE_PROMPT", payload: newPrompt });
//   };

//   return {
//     title,
//     coverImagePrompt,
//     coverOptions,
//     titleOptions,
//     selectedCover,
//     selectedTitle,
//     mergedImage,
//     isGeneratingCover,
//     isGeneratingTitle,
//     defaultCover,
//     handleSetCover,
//     handleGenerateCoverOptions,
//     handleGenerateTitleOptions,
//     handleInsertTitleIntoCover,
//     handleContinueToBook,
//     handleTitleChange,
//     handleCoverImagePromptChange,
//     handleSelectCover,
//     setSelectedTitle,
//     handleTitleSave,
//     backgroundGenerationStatus,
//     bookId: storyState.id,
//     coverImages
//   };
// };

// export default useStoryEditor;

// import { useAuth } from '../context/AuthContext';
// const { user } = useAuth();

// const [mergedImage, setMergedImage] = useState(null);
// const [isGeneratingTitle, setIsGeneratingTitle] = useState(false);
// const [titleImageStatusId, setTitleImageStatusId] = useState(null);
// const [mergeStatusId, setMergeStatusId] = useState(null);
// const [backgroundGenerationStatus, setBackgroundGenerationStatus] = useState(null);
